<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">
            {{ edit != null ? 'Editar Produto' : 'Adicionar Produto' }}
          </h4>

          <div class="page-title-right">
            <b-button
              variant="info"
              @click.prevent="$router.push({ name: 'produtos' })"
            >
              <i class="bx bx-list-ol font-size-16 align-middle mr-2"></i>
              Listar Produtos
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left">
      <div class="col-12 mb-3" v-if="error">
        <div class="card">
          <div class="card-body text-center bg-danger text-white">
            os campos marcados com
            <span>*</span>
            são obrigatórios
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-tabs
              pills
              justified
              v-model="tab"
              content-class="p-3 text-muted"
            >
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <span class="mr-1 text-danger">*</span>
                    1
                  </span>
                  <span class="d-none d-sm-inline-block">
                    <span class="mr-1 text-danger">*</span>
                    Geral
                  </span>
                </template>
                <div class="row border-top pt-4">
                  <div class="col-md-12 col-lg-6 mb-base">
                    <label>
                      <span class="mr-1 text-danger">*</span>
                      Nome do Produto
                    </label>
                    <b-form-group>
                      <b-form-input
                        placeholder="Nome do produto..."
                        for="text"
                        v-model="form.name"
                        :class="{ 'is-invalid': form && $v.form.name.$error }"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-12 col-lg-6 mb-3">
                    <label>
                      <span class="mr-1 text-danger">*</span>
                      Categoria
                    </label>
                    <multiselect
                      :options="options_categorys"
                      v-model="form.category"
                      class="helo"
                      label="name"
                      placeholder="Categoria"
                      select-label="Selecionar"
                      deselect-label="Remover"
                      :loading="isLoading"
                      @search-change="searchCategory"
                      :class="{
                        'is-invalid': form && $v.form.category.$error,
                      }"
                    ></multiselect>
                  </div>
                  <div class="col-md-12 col-lg-6 mb-3">
                    <label>Marca</label>
                    <multiselect
                      v-model="brand"
                      label="name"
                      :options="options_brands"
                      class="helo"
                      placeholder="Marca"
                      select-label="Selecionar"
                      deselect-label="Remover"
                      :loading="isLoadingBrand"
                      @search-change="searchBrand"
                    ></multiselect>
                  </div>
                  <div class="col-md-12 col-lg-6 mb-3">
                    <label>Fabricante</label>
                    <multiselect
                      v-model="manufacturer"
                      :options="options_manufacturers"
                      class="helo"
                      label="name"
                      placeholder="Fabricante"
                      select-label="Selecionar"
                      deselect-label="Remover"
                      :loading="isLoadingManufacturer"
                      @search-change="searchManufacturer"
                    ></multiselect>
                  </div>
                  <div class="col-12 mb-2">
                    <b-form-group>
                      <label>Descrição Curta</label>
                      <b-form-textarea
                        id="textarea"
                        placeholder="Descrição..."
                        rows="6"
                        max-rows="6"
                        v-model="form.description_short"
                      ></b-form-textarea>
                    </b-form-group>
                  </div>
                  <div class="col-12 mb-4">
                    <label>Descrição Longa</label>
                    <quill-editor
                      v-model="form.description_long"
                      ref="quillEditorA"
                    ></quill-editor>
                  </div>
                  <div class="col-12 mb-3">
                    <label>Características</label>
                    <quill-editor
                      v-model="form.characteristics"
                      ref="quillEditorA"
                    ></quill-editor>
                  </div>
                  <div class="col-12 py-3 text-right border-top">
                    <b-button variant="info" @click="next">
                      Continuar
                      <i
                        class="bx bx-chevron-right font-size-16 align-middle ml-2"
                      ></i>
                    </b-button>
                  </div>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">2</span>
                  <span class="d-none d-sm-inline-block">Imagens</span>
                </template>
                <div class="row border-top pt-4">
                  <div class="col-12 mb-4">
                    <label>Imagens do produto</label>
                    <label class="w-100 cursor-pointer">
                      <div class="uploadimg w-100 border p-5 text-center">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h6 class="text-muted">clique para enviar imagens</h6>
                      </div>
                      <input
                        type="file"
                        @change="onFileChange"
                        name="image[]"
                        multiple
                        class="d-none"
                      />
                    </label>
                  </div>
                  <div class="col-12" v-if="imgs.length > 0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Imagem</th>
                            <th scope="col">Ordem</th>
                            <th scope="col" class="text-center">Capa</th>
                            <th scope="col" class="text-center">Fundo</th>
                            <th scope="col" class="text-center">Ação</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="text-center align-middle"
                            v-for="(i, index) in imgs"
                            :key="index"
                          >
                            <td class="text-left align-middle">
                              <b-img
                                width="100"
                                :src="i.url"
                                rounded
                                alt=""
                              ></b-img>
                            </td>
                            <td class="align-middle">
                              <input
                                type="number"
                                class="form-control"
                                v-model="i.ordem"
                                min="0"
                                @change="ordemImage($event, index)"
                              />
                            </td>
                            <td class="align-middle">
                              <input
                                class="form-check-input position-static"
                                type="radio"
                                v-model="i.capa"
                                :checked="i.capa == 1 ? true : false"
                                value="1"
                                @click="altCheckCapa(index)"
                              />
                            </td>
                            <td class="align-middle">
                              <input
                                class="form-check-input position-static"
                                type="checkbox"
                                v-model="i.fundo"
                                :checked="i.fundo == 1 ? true : false"
                                value="1"
                                @click="altCheckFundo(index)"
                              />
                            </td>
                            <td class="align-middle">
                              <b-button
                                variant="danger"
                                @click.prevent="removerImagem(index)"
                              >
                                <i
                                  class="bx bx-trash font-size-16 align-middle"
                                ></i>
                              </b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    class="col-12 py-3 d-flex align-items-center justify-content-between border-top"
                  >
                    <b-button variant="light" @click="prev">
                      <i
                        class="bx bx-chevron-left font-size-16 align-middle mr-2"
                      ></i>
                      Voltar
                    </b-button>
                    <b-button variant="info" @click="next">
                      Continuar
                      <i
                        class="bx bx-chevron-right font-size-16 align-middle ml-2"
                      ></i>
                    </b-button>
                  </div>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <span class="mr-1 text-danger">*</span>
                    3
                  </span>
                  <span class="d-none d-sm-inline-block">
                    <span class="mr-1 text-danger">*</span>
                    Produto
                  </span>
                </template>
                <div class="row border-top pt-4">
                  <div class="col-md-12 col-lg-6 col-xl-3 mb-3">
                    <label>
                      <span class="mr-1 text-danger">*</span>
                      Preço do Produto
                    </label>
                    <b-input-group prepend="R$">
                      <b-form-input
                        v-model="form.price"
                        :class="{ 'is-invalid': form && $v.form.price.$error }"
                        placeholder="0,00"
                        v-money="money"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                  <div class="col-md-12 col-lg-6 col-xl-3 mb-2">
                    <b-form-group>
                      <label>
                        <span class="mr-1 text-danger">*</span>
                        Estoque
                      </label>
                      <b-form-input
                        placeholder="Estoque"
                        for="number"
                        min="0"
                        v-model="form.stock"
                        :class="{ 'is-invalid': form && $v.form.stock.$error }"
                      ></b-form-input>
                      <small>Quantidade de produtos em estoque</small>
                    </b-form-group>
                  </div>
                  <div class="col-md-12 col-lg-6 col-xl-3 mb-3">
                    <label>Status</label>
                    <multiselect
                      :options="options_status"
                      class="helo"
                      placeholder="Status"
                      select-label="Selecionar"
                      deselect-label="Remover"
                      label="text"
                      v-model="form.status"
                      :allow-empty="false"
                    ></multiselect>
                  </div>
                  <div class="col-md-12 col-lg-6 col-xl-3 mb-3">
                    <label>Frete Grátis</label>
                    <multiselect
                      :options="options_frete"
                      class="helo"
                      placeholder="Frete Grátis"
                      select-label="Selecionar"
                      deselect-label="Remover"
                      label="text"
                      v-model="form.frete"
                      :allow-empty="false"
                    ></multiselect>
                  </div>
                  <div class="col-12 mb-3 border-top pt-3" v-if="frete">
                    <h6>Informações para cálculo do frete</h6>
                  </div>
                  <div class="col-md-12 col-lg-3 mb-3" v-if="frete">
                    <label>Peso (g)</label>
                    <b-form-input
                      placeholder="g"
                      v-model="form.weight"
                      for="number"
                      min="0"
                    ></b-form-input>
                  </div>
                  <div class="col-md-12 col-lg-3 mb-3" v-if="frete">
                    <label>Largura (cm)</label>
                    <b-form-input
                      placeholder="cm"
                      v-model="form.width"
                      for="number"
                      min="0"
                    ></b-form-input>
                  </div>
                  <div class="col-md-12 col-lg-3 mb-3" v-if="frete">
                    <label>Altura (cm)</label>
                    <b-form-input
                      placeholder="cm"
                      v-model="form.height"
                      for="number"
                      min="0"
                    ></b-form-input>
                  </div>
                  <div class="col-md-12 col-lg-3 mb-3" v-if="frete">
                    <label>Profundidade (cm)</label>
                    <b-form-input
                      placeholder="cm"
                      v-model="form.depth"
                      for="number"
                      min="0"
                    ></b-form-input>
                  </div>
                  <div class="col-12 mb-3 border-top pt-3">
                    <h6>Modelo</h6>
                  </div>
                  <div class="col-md-12 col-lg-6 mb-3">
                    <label>Modelo</label>
                    <b-form-input
                      placeholder="Modelo do Produto"
                      for="text"
                      v-model="form.model"
                    ></b-form-input>
                  </div>
                  <div class="col-md-12 col-lg-3 mb-3">
                    <label>SKU</label>
                    <b-form-input
                      v-model="form.SKU"
                      placeholder="SKU"
                      for="text"
                    ></b-form-input>
                  </div>
                  <div class="col-md-12 col-lg-3 mb-3">
                    <label>UPC</label>
                    <b-form-input
                      v-model="form.UPC"
                      placeholder="UPC"
                      for="text"
                    ></b-form-input>
                  </div>
                  <div class="col-md-12 col-lg-4 mb-3">
                    <label>EAN</label>
                    <b-form-input
                      v-model="form.EAN"
                      placeholder="EAN"
                      for="text"
                    ></b-form-input>
                  </div>
                  <div class="col-md-12 col-lg-4 mb-3">
                    <label>JAN</label>
                    <b-form-input
                      v-model="form.JAN"
                      placeholder="JAN"
                      for="text"
                    ></b-form-input>
                  </div>
                  <div class="col-md-12 col-lg-4 mb-3">
                    <label>ISBN</label>
                    <b-form-input
                      v-model="form.ISBN"
                      placeholder="ISBN"
                      for="text"
                    ></b-form-input>
                  </div>
                  <div class="col-12 mb-3 border-top pt-3">
                    <h6>Variações</h6>
                  </div>
                  <div class="col-md-12 col-md-12 col-lg-9 mb-3">
                    <label>Opção</label>
                    <multiselect
                      :options="options"
                      class="helo"
                      placeholder="Opção"
                      select-label="Selecionar"
                      deselect-label="Remover"
                      label="name"
                      :allow-empty="false"
                      :loading="isLoadingOptions"
                      @search-change="searchOptions"
                      v-model="option"
                      :class="{ 'is-invalid': error }"
                    ></multiselect>
                  </div>
                  <div class="col-md-12 col-md-12 col-lg-3 mb-3">
                    <label>Adicionar</label>
                    <b-button variant="info" class="w-100" @click="addOption()">
                      Adicionar
                    </b-button>
                  </div>

                  <div class="col-12">
                    <div
                      class="row"
                      v-for="(op, index) in optionsSelec"
                      :key="index"
                    >
                      <div
                        class="col-12 mb-3 d-flex justify-content-between align-items-center bg-light p-2"
                      >
                        <h6 class="w-100">{{ op.name }}</h6>
                        <b-button
                          variant="danger"
                          @click.prevent="removerOption(op)"
                        >
                          Remover
                        </b-button>
                      </div>
                      <div class="col-md-12 col-md-12 col-lg-9 mb-3">
                        <label>Valor</label>
                        <multiselect
                          :options="op.options"
                          class="helo"
                          placeholder="Valor"
                          select-label="Selecionar"
                          deselect-label="Remover"
                          label="name"
                          :allow-empty="false"
                          v-model="optionValue[op.name]"
                        ></multiselect>
                      </div>
                      <div class="col-md-12 col-md-12 col-lg-3 mb-3">
                        <label>Ação</label>
                        <b-button
                          variant="info"
                          class="w-100"
                          @click="addOptionItem(op.name)"
                        >
                          +
                        </b-button>
                      </div>
                      <div class="col-12">
                        <div
                          class="row"
                          v-for="(opp, index) in valoresoptions[op.name]"
                          :key="index"
                        >
                          <div class="col-md-12 col-md-12 col-lg-6 mb-3">
                            <b-form-input
                              disabled
                              class="bg-light"
                              v-model="opp.name"
                              for="text"
                            ></b-form-input>
                          </div>
                          <div class="col-md-12 col-md-12 col-lg-3 mb-3">
                            <b-form-input
                              type="number"
                              v-model.number="opp.amount"
                              placeholder="Quantidade em Estoque"
                              for="number"
                            ></b-form-input>
                          </div>
                          <div class="col-md-12 col-md-12 col-lg-3 mb-3">
                            <b-button
                              variant="danger"
                              class="w-100"
                              @click.prevent="removerOptionItem(opp, op.name)"
                            >
                              -
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 py-3 d-flex align-items-center justify-content-between border-top"
                  >
                    <b-button variant="light" @click="prev">
                      <i
                        class="bx bx-chevron-left font-size-16 align-middle mr-2"
                      ></i>
                      Voltar
                    </b-button>
                    <b-button variant="info" @click="next">
                      Continuar
                      <i
                        class="bx bx-chevron-right font-size-16 align-middle ml-2"
                      ></i>
                    </b-button>
                  </div>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">4</span>
                  <span class="d-none d-sm-inline-block">Desconto</span>
                </template>
                <div class="row border-top pt-4">
                  <!--<div class="col-md-12 col-lg-3 mb-3">
                    <label>Quantidade</label>
                    <b-form-input
                      placeholder="Quantidade"
                      for="number"
                      min="0"
                      v-model="form.discount_quantity"
                    ></b-form-input>
                    <small>Quantidade de produtos</small>
                  </div>-->
                  <div class="col-md-12 col-lg-4 mb-3">
                    <label>Preço Promocional</label>
                    <b-input-group prepend="R$">
                      <b-form-input
                        placeholder="0,00"
                        v-money="money"
                        v-model="form.discount_price"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                  <div class="col-md-12 col-lg-4 mb-3">
                    <label>Data Início</label>
                    <b-form-datepicker
                      id="dateinicio"
                      placeholder="Data de Início"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      v-model="form.discount_date_start"
                    ></b-form-datepicker>
                  </div>
                  <div class="col-md-12 col-lg-4 mb-3">
                    <label>Data Final</label>
                    <b-form-datepicker
                      id="datefinal"
                      placeholder="Data Final"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      v-model="form.discount_date_end"
                    ></b-form-datepicker>
                  </div>
                  <div
                    class="col-12 py-3 d-flex align-items-center justify-content-between border-top"
                  >
                    <b-button variant="light" @click="prev">
                      <i
                        class="bx bx-chevron-left font-size-16 align-middle mr-2"
                      ></i>
                      Voltar
                    </b-button>
                    <b-button variant="info" @click="next">
                      Continuar
                      <i
                        class="bx bx-chevron-right font-size-16 align-middle ml-2"
                      ></i>
                    </b-button>
                  </div>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">5</span>
                  <span class="d-none d-sm-inline-block">Digital</span>
                </template>
                <div class="row border-top pt-4">
                  <div class="col-12 mb-3">
                    <label>Produto Digital</label>
                    <multiselect
                      :options="options_digital"
                      class="helo"
                      label="text"
                      placeholder="Produto Digital?"
                      select-label="Selecionar"
                      deselect-label="Remover"
                      v-model="form.digital"
                      :allow-empty="false"
                    ></multiselect>
                    <small>
                      Produtos digitais são liberados para download após a
                      confirmação do pagamento.
                    </small>
                  </div>
                  <div class="col-12 mb-3" v-if="form.digital.value == 1">
                    <label>Upload do produto</label>
                    <label class="w-100 cursor-pointer">
                      <div class="uploadimg w-100 border p-5 text-center">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h6 class="text-muted">clique para enviar arquivos</h6>
                      </div>
                      <input
                        type="file"
                        @change="onFileChangeArquivos"
                        name="file[]"
                        multiple
                        class="d-none"
                      />
                    </label>
                  </div>
                  <div class="col-12 mb-3" v-if="files.length > 0">
                    <b-list-group>
                      <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                        v-for="(f, index) in files"
                        :key="index"
                      >
                        <p>
                          <b-input-group
                            size="lg"
                            :append="'(' + f.tipo + ') | ' + f.size + ' MB'"
                          >
                            <input
                              class="form-control"
                              type="text"
                              @change="nameFile($event, index)"
                              v-model="f.name"
                            />
                          </b-input-group>
                        </p>
                        <b-button
                          variant="danger"
                          @click.prevent="removerFile(index)"
                        >
                          <i class="bx bx-trash font-size-16 align-middle"></i>
                        </b-button>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div
                    class="col-12 py-3 d-flex align-items-center justify-content-between border-top"
                  >
                    <b-button variant="light" @click="prev">
                      <i
                        class="bx bx-chevron-left font-size-16 align-middle mr-2"
                      ></i>
                      Voltar
                    </b-button>
                    <b-button variant="info" @click="next">
                      Continuar
                      <i
                        class="bx bx-chevron-right font-size-16 align-middle ml-2"
                      ></i>
                    </b-button>
                  </div>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">6</span>
                  <span class="d-none d-sm-inline-block">SEO</span>
                </template>
                <div class="row border-top pt-4">
                  <div class="col-12 mb-3">
                    <label>Meta Título</label>
                    <b-form-group>
                      <b-form-input
                        placeholder="Meta título..."
                        for="text"
                        v-model="form.meta_title"
                      ></b-form-input>
                      <small>Título para motores de busca.</small>
                    </b-form-group>
                  </div>
                  <div class="col-12 mb-3">
                    <label>Meta Descrição</label>
                    <b-form-textarea
                      id="textareades"
                      placeholder="Meta descrição..."
                      rows="6"
                      max-rows="6"
                      v-model="form.meta_description"
                    ></b-form-textarea>
                    <small>Descrição para motores de busca.</small>
                  </div>
                  <div
                    class="col-12 py-3 d-flex align-items-center justify-content-between border-top"
                  >
                    <span>
                      <b-button variant="light" @click="prev">
                        <i
                          class="bx bx-chevron-left font-size-16 align-middle mr-2"
                        ></i>
                        Voltar
                      </b-button>
                      <b-button
                        variant="danger"
                        @click.prevent="$router.push({ name: 'produtos' })"
                        class="ml-2"
                      >
                        <i class="bx bx-x font-size-16 align-middle mr-2"></i>
                        Cancelar
                      </b-button>
                    </span>
                    <b-button variant="success" @click="salvarDados()">
                      <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                      Cadastrar
                    </b-button>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.uploadimg {
  border: 3px dashed #ccc !important;
}
.uploadimg:hover {
  cursor: pointer;
}
.is-invalid .multiselect__tags {
  border-color: #f46a6a !important;
  border: 1px solid #f46a6a !important;
}
</style>
<script>
import { mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
export default {
  props: {
    edit: {
      type: Object,
      default: function () {
        return null
      },
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      tab: 0,
      brand: null,
      manufacturer: null,
      options_status: [
        { value: 0, text: 'Ativo' },
        { value: 1, text: 'Bloqueado' },
      ],
      options_frete: [
        { value: 0, text: 'Não' },
        { value: 1, text: 'Sim' },
      ],
      options_categorys: [],
      options_brands: [],
      options_manufacturers: [],
      options_digital: [
        { value: 0, text: 'Não' },
        { value: 1, text: 'Sim' },
      ],
      imgs: [],
      files: [],
      form: {
        status: { value: 0, text: 'Ativa' },
        digital: { value: 0, text: 'Não' },
        name: null,
        price: 0,
        stock: null,
        frete: { value: 0, text: 'Não' },
        category: null,
        EAN: '',
        ISBN: '',
        JAN: '',
        SKU: '',
        UPC: '',
        characteristics: '',
        depth: null,
        description_long: '',
        description_short: '',
        discount_price: null,
        discount_quantity: 0,
        height: null,
        meta_description: '',
        meta_title: '',
        model: '',
        weight: null,
        width: null,
        options: '',
      },
      filtroCategory: {
        limit: 10,
        status: 0,
      },
      filtroOptions: {
        limit: 10,
      },
      isLoading: false,
      isLoadingOptions: false,
      filtroBrand: {
        limit: 10,
        status: 0,
      },
      isLoadingBrand: false,
      filtroManufacturer: {
        limit: 10,
        status: 0,
      },
      isLoadingManufacturer: false,
      error: false,
      frete: true,
      options: [],
      option: {},
      error: false,
      optionsSelec: [],
      valoresoptions: [],
      optionValue: [],
    }
  },
  validations: {
    form: {
      name: { required },
      price: { required },
      stock: { required },
      category: { required },
    },
  },
  watch: {
    'form.frete': function () {
      this.frete = this.form.frete.value == 0 ? true : false
    },
  },

  mounted() {
    this.listCategory()
    this.listBrand()
    this.listManufacturer()
    this.listOption()
    if (this.edit != null) {
      this.form = this.edit

      this.form.price = this.form.price
      this.form.discount_price = this.form.discount_price

      this.form.status = this.options_status[this.edit.status]
      this.form.frete = {
        value: this.edit.frete,
        text: this.edit.frete == 0 ? 'Não' : 'Sim',
      }
      this.form.digital = {
        value: this.edit.digital,
        text: this.edit.digital == 0 ? 'Não' : 'Sim',
      }

      if (this.edit.manufacturer != null) {
        this.manufacturer_id = this.edit.manufacturer.manufacturer.id
        this.manufacturer = this.edit.manufacturer.manufacturer
      }
      if (this.edit.brand != null) {
        this.brand_id = this.edit.brand.brand.id
        this.brand = this.edit.brand.brand
      }
      var dataStart = new Date(this.edit.discount_date_start)
      var start = new Date(
        dataStart.getDay() +
          '/' +
          dataStart.getMonth() +
          '/' +
          dataStart.getFullYear(),
      )

      var dataEnd = new Date(this.edit.discount_date_end)
      var end = new Date(
        dataEnd.getDay() +
          '/' +
          dataEnd.getMonth() +
          '/' +
          dataEnd.getFullYear(),
      )
      if (start.toString() == 'Invalid Date') {
        this.form.discount_date_start = null
      }
      if (end.toString() == 'Invalid Date') {
        this.form.discount_date_end = null
      }

      if (this.edit.image != null) {
        this.edit.image.forEach((element) => {
          var g = {
            file: null,
            url: element.image300,
            capa: element.capa,
            fundo: element.fundo,
            ordem: element.ordem,
            id: element.id,
          }
          this.imgs.push(g)
        })
      }
      if (this.edit.file != null) {
        this.edit.file.forEach((element) => {
          var g = {
            file: element,
            name: element.name,
            size: element.mega,
            tipo: element.tipo,
            id: element.id,
          }
          this.files.push(g)
        })
      }
    }
  },
  methods: {
    ...mapActions('product', [
      'ActionProductAll',
      'ActionProductCreate',
      'ActionProductUpdate',
      'ActionProductDeleteImage',
      'ActionProductUpdateImage',
      'ActionProductDeleteFile',
      'ActionProductUpdateFile',
    ]),
    ...mapActions('category', ['ActionCategoryAll']),
    ...mapActions('brand', ['ActionBrandAll']),
    ...mapActions('manufacturer', ['ActionManufacturerAll']),
    ...mapActions('option', ['ActionOptionAll']),

    addOption() {
      if (
        this.option.name &&
        this.option.name != null &&
        this.option.name != ''
      ) {
        this.option.options =
          typeof this.option.options == 'string'
            ? JSON.parse(this.option.options)
            : this.option.options
        this.option.created_at = ''
        this.option.updated_at = ''
        var ty = 0
        this.optionsSelec.forEach((element) => {
          if (element.name == this.option.name) {
            ty = 1
          }
        })

        if (ty == 0) {
          this.optionsSelec.push(this.option)
          this.valoresoptions[this.option.name] = []
        }
      } else {
        this.error = true
      }
    },

    addOptionItem(name) {
      var y = true
      this.valoresoptions[name].forEach((element) => {
        if (element.name == this.optionValue[name].name) {
          y = false
        }
      })
      if (y) {
        if (!this.valoresoptions[name]) {
          this.valoresoptions[name] = []
        }
        this.valoresoptions[name].push(this.optionValue[name])
        this.optionValue = []
      }
    },

    removerOptionItem(op, name) {
      this.valoresoptions[name].splice(this.valoresoptions[name].indexOf(op), 1)
      var ty = this.valoresoptions
      this.valoresoptions = []
      this.valoresoptions = ty
    },

    removerOption(op) {
      this.optionsSelec.splice(this.optionsSelec.indexOf(op), 1)
    },

    salvarDados() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.error = false
        this.submitProdut()
      } else {
        this.error = true
      }
    },
    async submitProdut() {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })

        let data = new FormData()
        this.form.category_id = this.form.category.id
        this.form.status = this.form.status.value

        if (this.brand != null) {
          this.form.brand_id = this.brand.id
          data.append('brand_id', parseInt(this.form.brand_id))
        }
        if (this.manufacturer != null) {
          this.form.manufacturer_id = this.manufacturer.id
          data.append('manufacturer_id', parseInt(this.form.manufacturer_id))
        }
        if (this.form.digital != null) {
          this.form.digital = this.form.digital.value
        }

        data.append('name', this.form.name)
        data.append('EAN', this.form.EAN)
        data.append('ISBN', this.form.ISBN)
        data.append('JAN', this.form.JAN)
        data.append('SKU', this.form.SKU)
        data.append('UPC', this.form.UPC)
        data.append('category_id', this.form.category_id)
        data.append('characteristics', this.form.characteristics)
        data.append('depth', this.form.depth == null ? 0 : this.form.depth)
        data.append('description_long', this.form.description_long)
        data.append('description_short', this.form.description_short)
        data.append('digital', this.form.digital)
        if (this.form.discount_date_end != null) {
          data.append(
            'discount_date_end',
            moment(this.form.discount_date_end).format('YYYY-MM-DDTHH:mm:ssZ'),
          )
        }
        if (this.form.discount_date_start != null) {
          data.append(
            'discount_date_start',
            moment(this.form.discount_date_start).format(
              'YYYY-MM-DDTHH:mm:ssZ',
            ),
          )
        }
        data.append('discount_price', this.form.discount_price)
        data.append('discount_quantity', this.form.discount_quantity)
        data.append('frete', this.form.frete.value)
        data.append('height', this.form.height == null ? 0 : this.form.height)
        data.append('meta_description', this.form.meta_description)
        data.append('meta_title', this.form.meta_title)
        data.append('model', this.form.model)
        data.append('price', this.form.price)
        data.append('status', this.form.status)
        data.append('stock', this.form.stock)
        data.append('weight', this.form.weight == null ? 0 : this.form.weight)
        data.append('width', this.form.width == null ? 0 : this.form.width)

        if (this.optionsSelec.length > 0) {
          var options = this.optionsSelec
          options.forEach((value, index) => {
            var opt = {
              cor: options[index].cor,
              name: options[index].name,
              options: this.valoresoptions[value.name],
              type: options[index].type,
              required: options[index].required,
            }
            if (this.edit != null && this.edit.options != null && this.edit.options != '') {
              opt.id = this.valoresoptions[value.name].id
            }
            data.append('options_produto[]', JSON.stringify(opt))
          })
        }

        if (this.imgs.length > 0) {
          this.imgs.forEach((element) => {
            if (element.id == null) {
              data.append('image[]', element.file)
              data.append('capa[]', element.capa)
              data.append('fundo[]', element.fundo)
              data.append('ordem[]', element.ordem)
            }
          })
        }

        if (this.files.length > 0) {
          this.files.forEach((element) => {
            if (element.id == null) {
              data.append('file[]', element.file)
              data.append('name_file[]', element.name)
              data.append('tipo_file[]', element.tipo)
              data.append('mega_file[]', element.size)
            }
          })
        }

        if (this.edit != null) {
          data.append('id', this.form.id)
          var dados = {
            data: data,
            id: this.form.id,
          }
          await this.ActionProductUpdate(dados)
        } else {
          await this.ActionProductCreate(data)
        }

        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        }).then((_) => {
          this.$router.push({ name: 'produtos' })
        })
      } catch (e) {
        console.log(e)
        this.$swal({
          title: 'Erro!',
          text: 'Ocorreu um erro ao realizar ação!',
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    searchCategory(e) {
      this.filtroCategory.search = e
      this.listCategory()
    },

    searchOptions(e) {
      this.filtroOptions.search = e
      this.listOption()
    },
    async listCategory() {
      try {
        this.isLoading = true
        await this.ActionCategoryAll(this.filtroCategory).then((res) => {
          this.options_categorys = res.data.data.list
          this.isLoading = false
        })
      } catch (_) {
        this.options_categorys = []
      }
    },

    async listOption() {
      try {
        this.isLoadingOption = true
        await this.ActionOptionAll(this.filterOptions).then((res) => {
          this.options = res.data.data.list
          this.optionsSelectLoading()
          this.isLoadingOption = false
        })
      } catch (_) {
        this.options = []
      }
    },

    optionsSelectLoading() {
      if (
        this.edit != null &&
        this.edit.options != null &&
        this.edit.options.length > 0
      ) {

        this.options.forEach((element) => {
          this.edit.options.forEach((e) => {
            this.valoresoptions[e.name] = e.options
            this.valoresoptions[e.name].id = e.id
            if (e.name == element.name) {
              element.options = JSON.parse(element.options)
              this.optionsSelec.push(element)
            }
          })
        })
      }
    },

    searchBrand(e) {
      this.filtroBrand.search = e
      this.listBrand()
    },

    async listBrand() {
      try {
        this.isLoadingBarnd = true
        await this.ActionBrandAll(this.filtroBrand).then((res) => {
          this.options_brands = res.data.data.list
          this.isLoadingBarnd = false
        })
      } catch (_) {
        this.options_brands = []
      }
    },

    searchManufacturer(e) {
      this.filtroManufacturer.search = e
      this.listManufacturer()
    },

    async listManufacturer() {
      try {
        this.isLoadingManufacturer = true
        await this.ActionManufacturerAll(this.filtroBrand).then((res) => {
          this.options_manufacturers = res.data.data.list
          this.isLoadingManufacturer = false
        })
      } catch (_) {
        this.options_manufacturers = []
      }
    },
    next() {
      this.tab = (this.tab + 1) % 6
    },
    prev() {
      this.tab = (this.tab - 1) % 6
    },

    onFileChange(e) {
      e.target.files.forEach((element, index) => {
        var g = {
          file: element,
          url: URL.createObjectURL(element),
          capa: 0,
          fundo: 0,
          ordem: this.imgs.length > 0 ? this.imgs.length + 1 - 1 : index,
          id: null,
        }
        this.imgs.push(g)
        if (
          (this.edit != null && this.edit.image == null) ||
          this.edit == null
        ) {
          this.imgs[0].capa = 1
        }
      })
    },

    removerImagem(e) {
      var img = this.imgs[e]
      this.imgs.splice(e, 1)
      if (img.id != null && img.id > 0) {
        this.ActionProductDeleteImage(img.id).then((_) => {
          var c = 0
          if (img.capa == 1) {
            c = 1
          }
          if (img.fundo == 1) {
            c = c + 2
          }
          if (c > 0 && this.imgs.length > 0) {
            if (this.imgs[0].id == null) {
              if (c == 1) {
                this.imgs[0].capa = 1
              } else if (c >= 2) {
                this.imgs[0].fundo = 1
              }
            } else {
              if (c == 1) {
                this.imgs[0].capa = 1
              } else if (c >= 2) {
                this.imgs[0].fundo = 1
              }
              var fg = {
                id: this.imgs[0].id,
                data: this.imgs[0],
              }
              this.ActionProductUpdateImage(fg)
            }
          }
        })
      } else {
        if (img.capa == 1 && this.imgs.length > 0) {
          this.imgs[0].capa = 1
        }
        if (img.fundo == 1 && this.imgs.length > 0) {
          this.imgs[0].fundo = 1
        }
      }
    },
    altCheckCapa(i) {
      var t = []
      this.imgs.forEach((element) => {
        if (element.id != null && element.capa == 1) {
          element.capa = 0
          var fg = {
            id: element.id,
            data: element,
          }
          this.ActionProductUpdateImage(fg)
        }
        element.capa = 0
        t.push(element)
      })
      t[i].capa = 1
      if (t[i].id != null) {
        var fg = {
          id: t[i].id,
          data: t[i],
        }
        this.ActionProductUpdateImage(fg)
      }
      this.imgs = t
    },
    altCheckFundo(i) {
      this.imgs[i].fundo = this.imgs[i].fundo == 1 ? 0 : 1
      if (this.imgs[i].id != null) {
        var fg = {
          id: this.imgs[i].id,
          data: this.imgs[i],
        }
        this.ActionProductUpdateImage(fg)
      }
    },

    onFileChangeArquivos(e) {
      e.target.files.forEach((element) => {
        var size = (element.size / 1024 / 1024).toFixed(2)
        var g = {
          file: element,
          name: element.name,
          size: size,
          tipo: element.type,
          id: null,
        }
        this.files.push(g)
      })
    },
    removerFile(e) {
      if (this.files[e].id != null) {
        this.ActionProductDeleteFile(this.files[e].id)
      }
      this.files.splice(e, 1)
    },
    nameFile(event, index) {
      if (this.files[index].id != null) {
        this.files[index].name = event.target.value
        var fg = {
          id: this.files[index].id,
          data: this.files[index],
        }
        this.ActionProductUpdateFile(fg)
      }
    },
    ordemImage(event, index) {
      if (this.imgs[index].id != null) {
        this.imgs[index].ordem = parseInt(
          event.target.value == '' ? 0 : event.target.value,
        )
        var fg = {
          id: this.imgs[index].id,
          data: this.imgs[index],
        }
        this.ActionProductUpdateImage(fg)
      }
    },
  },
}
</script>
